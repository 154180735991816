import { createContext, useState, useEffect } from "react";
import moment from "moment";
import { useCookies } from "react-cookie";

import ReportsService from "../services/reports.service";

export const ReportsContext = createContext();
export const ReportsProvider = ({ children }) => {
  const [startDate, setStartDate] = useState(
    moment().add(-7, "days").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(0);
  const [overview, setOverview] = useState({});
  const [sms, setSms] = useState([]);
  const [email, setEmail] = useState([]);
  const [guestbook, setGuestbook] = useState([]);
  const [rental, setRental] = useState([]);
  const [guide, setGuide] = useState([]);
  const [history, setHistory] = useState([]);
  const [macros, setMacros] = useState([]);
  const [fivestar, setFivestar] = useState([]);
  const [extendStay, setExtendStay] = useState([]);
  const [guestChecks, setGuestChecks] = useState([]);
  const [webchats, setWebChats] = useState([]);
  const [active, setActive] = useState(0);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);

  const initReports = () => {
    updateRange(
      moment().subtract(7, "day").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    );
  };

  const updateRange = async (startDate, endDate) => {
    setStartDate(moment(startDate).format("YYYY-MM-DD"));
    setEndDate(moment(endDate).format("YYYY-MM-DD"));
  };

  const getAutomatedSms = async (
    start,
    end,
    page = 0,
    per = 20,
    filter = "",
  ) => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setSms(
      await ReportsService.getAutomated(
        "sms",
        start,
        end,
        page,
        per,
        filter,
      ).then((response) => response.data),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getAutomatedEmail = async (
    start,
    end,
    page = 0,
    per = 20,
    filter = "",
  ) => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setEmail(
      await ReportsService.getAutomated(
        "email",
        start,
        end,
        page,
        per,
        filter,
      ).then((response) => response.data),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getGuide = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setGuide(
      await ReportsService.getDestinations(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getRental = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setRental(
      await ReportsService.getRental(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getFiveStar = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setFivestar(
      await ReportsService.getFiveStar(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getExtendStay = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setExtendStay(
      await ReportsService.getExtendStay(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getGuestChecks = async (
    start,
    end,
    page = 0,
    per = 20,
    filter = "",
  ) => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setGuestChecks(
      await ReportsService.getGuestChecks(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getWebChat = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setWebChats(
      await ReportsService.getWebChat(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getGuestbook = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setGuestbook(
      await ReportsService.getGuestBooks(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getHistory = async (start, end, page = 0, per = 20, filter = "") => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setHistory(
      await ReportsService.getHistory(start, end, page, per, filter).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getOverview = async (start, end) => {
    setLoading((loadingStatus) => loadingStatus + 1);
    setOverview(
      await ReportsService.getOverviews(start, end).then(
        (response) => response.data,
      ),
    );
    setLoading((loadingStatus) => loadingStatus - 1);
  };

  const getMacros = async () => {
    setMacros(
      await ReportsService.getMacros().then((response) => response.data),
    );
  };

  const resetParams = () => {
    setPage(0);
    setFilter("");
  };

  let reportsCtx = {
    loading,
    overview,
    sms,
    email,
    guestbook,
    rental,
    history,
    guide,
    startDate,
    endDate,
    active,
    macros,
    fivestar,
    extendStay,
    guestChecks,
    webchats,
    page,
    filter,

    setActive,
    setPage,
    setFilter,
    setMacros,
    setFivestar,
    initReports,
    updateRange,
    getAutomatedSms,
    getAutomatedEmail,
    getGuide,
    getRental,
    getHistory,
    getGuestbook,
    getOverview,
    getMacros,
    getFiveStar,
    getExtendStay,
    getGuestChecks,
    getWebChat,
    resetParams,
  };

  return (
    <ReportsContext.Provider value={reportsCtx}>
      {children}
    </ReportsContext.Provider>
  );
};
