import {
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { Image } from "uikit-react";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";

import { AppContexts } from "../../../providers";
import { Form, FormRow, FormInputWrapper, Link } from "../../../ui";
import Tags from "../../common/Tags";
import MasterConfirm from "../../common/Confirm";
import MacrosReservationKeys from "../../common/MacroReservationKeys";

const schema = yup.object().shape({
  title: yup.string().required(),
});

const defaultValues = {
  welcome_guide_question_id: 0,
  parent_id: 0,
  rental_id: 0,
  title: "",
  body: "",
  tags: [],
  attachment: "",
  video: "",
  icon: null,
  thumbnail: null,
  expanded: false,
};

export const MODAL_NAME = "master-property-guide-edit";

const PropertyGuideEdit = () => {
  const { user, refresh } = useContext(AppContexts.AuthContext);
  const { uploadFile, genUUID } = useContext(AppContexts.UtilContext);
  const { get, set, settings } = useContext(AppContexts.SettingsContext);
  const {
    createSection,
    updateSection,
    selectedSection,
    setSelectedSection,
    selectedTab,
    rental,
    getRental,
  } = useContext(AppContexts.RentalsContext);
  const offcanvasRef = useRef(null);

  // const [attachments, setAttachments] = useState([]);
  const [attachImage, setAttachImage] = useState({});
  const [attachPDF, setAttachPDF] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleBeforeHide = (event) => {
    if (event.target === offcanvasRef.current) {
      clearErrors();
      setSelectedSection({});
    }
  };

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  useEffect(() => {
    setForm();
  }, [selectedSection]);

  let isMaster = useMemo(() => {
    return !rental || !rental.rental_id;
  }, [rental]);

  const setForm = () => {
    clearErrors();
    setAttachImage({});
    setAttachPDF({});
    if (!selectedSection || Object.keys(selectedSection).length === 0) {
      clearForms();
    } else {
      Object.keys(defaultValues).forEach((formname) => {
        setValue(
          formname,
          selectedSection[formname]
            ? selectedSection[formname]
            : defaultValues[formname],
        );
      });

      let attachment = selectedSection.attachments?.find(
        (attachment) => attachment.type === "file",
      );
      setValue("attachment", attachment?.original_name);

      let video = selectedSection.attachments?.find(
        (attachment) => attachment.type === "you_tube",
      );
      setValue("video", video?.url);

      let attachImage = selectedSection.attachments?.find(
        (attachment) => attachment.type === "image",
      );
      if (attachImage) setAttachImage(attachImage);

      let attachPDF = selectedSection.attachments?.find(
        (attachment) => attachment.type === "file",
      );
      if (attachPDF) setAttachPDF(attachPDF);
    }
  };

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
  }, []);

  const handleRegister = async (formdata) => {
    let attachments_buf = [];
    let thumbnail_url;
    let attachment_url;

    if (formdata.thumbnail?.length > 0) {
      thumbnail_url = await uploadFile(
        formdata.thumbnail[0],
        `welcome_guide/attachments/${genUUID()}`,
      );
    }

    if (formdata.attachments?.length > 0) {
      attachment_url = await uploadFile(
        formdata.attachments[0],
        `workorders/attachments/${genUUID()}`,
      );
    }

    if (!!attachImage?.original_name) {
      if (thumbnail_url) {
        attachments_buf.push({ ...attachImage, url: thumbnail_url });
      } else {
        attachments_buf.push(attachImage);
      }
    }

    if (!!attachPDF?.original_name) {
      if (attachment_url) {
        attachments_buf.push({
          ...attachPDF,
          url: attachment_url,
        });
      } else {
        attachments_buf.push(attachPDF);
      }
    }

    if (formdata.video) {
      attachments_buf.push({
        type: "you_tube",
        name: "",
        original_name: "",
        url: formdata.video,
      });
    }

    formdata.attachments = attachments_buf;

    let plaindata = JSON.parse(JSON.stringify(formdata));

    try {
      let saveFn = updateSection;

      if (!!rental && !!rental.rental_id) {
        if (!selectedSection) {
          return console.log("Error updating section");
        }

        if (+plaindata.parent_id === 0 || +plaindata.rental_id === 0) {
          plaindata.parent_id = +selectedSection.welcome_guide_question_id;
          plaindata.rental_id = +rental.rental_id;

          saveFn = createSection;
        }
      }

      setLoading(true);
      let [section, sectionErr] = await saveFn({ ...plaindata });
      if (sectionErr !== null || section === null) {
        UIkit.notification("Failed", "error");
      } else {
        UIkit.modal(`#${MODAL_NAME}`).hide();
        UIkit.notification("Edited Successfully", "success");

        if (!!rental && !!rental.rental_id) {
          await Promise.all([refresh(), getRental(rental.rental_id)]);
        } else {
          await Promise.all([refresh()]);
        }

        setLoading(false);
        clearForms();
      }
    } catch (err) {
      console.log("Error", err);
      UIkit.notification("Failed", "error");
      setLoading(false);
    }
  };

  const handleClose = () => {
    document.body.style.overflow = "unset";
    UIkit.modal(`#${MODAL_NAME}`).hide();
  };

  const deleteImage = () => {
    setValue("thumbnail", null);
    setAttachImage({});
  };

  const deletePDF = () => {
    setValue("attachments", null);
    setAttachPDF({});
  };

  const body = useMemo(() => getValues("body"), [watch("body")]);
  const tags = useMemo(() => getValues("tags"), [watch("tags")]);
  const thumbnail = useMemo(() => getValues("thumbnail"), [watch("thumbnail")]);
  const icon = useMemo(() => getValues("icon"), [watch("icon")]);

  const handleError = (err) => console.error(err);

  return (
    <>
      <div
        id={MODAL_NAME}
        ref={offcanvasRef}
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">Edit Block</h2>
            </div>
            <div className="uk-modal-body">
              <Form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                {isMaster && (
                  <FormRow>
                    <FormInputWrapper label="Title*" size="one">
                      <InputContainer>
                        <input
                          className={classnames("uk-input", {
                            error: errors["title"],
                          })}
                          type="text"
                          {...register("title")}
                        />
                      </InputContainer>
                    </FormInputWrapper>
                  </FormRow>
                )}
                {isMaster && (
                  <FormRow>
                    <FormInputWrapper label="Tags">
                      <div className="hms-add-tag">
                        <Tags
                          value={tags}
                          onChange={(editedTags) =>
                            setValue("tags", editedTags)
                          }
                        />
                      </div>
                    </FormInputWrapper>
                  </FormRow>
                )}
                <FormRow>
                  <FormInputWrapper label="Text" size="two">
                    <span className="hms-align-right">
                      <Link className="m-l-10">
                        <a
                          href="https://intercom.help/ruebarueapp/en/articles/9792129-style-shortcuts"
                          target="_blank"
                        >
                          View Styles
                        </a>
                      </Link>
                    </span>
                    <InputContainer>
                      <textarea
                        className={classnames("uk-textarea", {
                          error: errors.body,
                        })}
                        value={body || ""}
                        onChange={(event) =>
                          setValue("body", event.target.value)
                        }
                        rows="7"
                      />
                      <div className="large-msg-typing-btn">
                        <span uk-tooltip="View Macros" className="macro-icon">
                          <img alt="macro-icon" src="/images/macro-icon.svg" />
                        </span>
                        <MacrosReservationKeys />
                      </div>
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <div className="uk-width-1-2@s uk-first-column">
                  <div className="uk-form-label">Photo</div>
                  <div style={{ alignItems: "center" }}>
                    <div className="js-upload uk-placeholder hms-file-uploader uk-text-center hms-file-delete">
                      <div data-uk-form-custom>
                        {!!(attachImage.name || attachImage.original_name) ? (
                          <>
                            <input
                              key="thumnail_key"
                              type="file"
                              accept="image/*"
                              {...register("thumbnail")}
                              onChange={(evt) =>
                                setAttachImage({
                                  name: evt.target.files[0]?.name,
                                  original_name: evt.target.files[0]?.name,
                                  type: "image",
                                  url: "",
                                })
                              }
                            />
                            {attachImage?.name || attachImage?.original_name}
                          </>
                        ) : (
                          <>
                            <div>
                              <Image
                                src="/images/upload-image.svg"
                                className="hms-navbar"
                              />
                            </div>
                            <input
                              key="thumnail_key"
                              type="file"
                              accept="image/*"
                              {...register("thumbnail")}
                              onChange={(evt) =>
                                setAttachImage({
                                  name: evt.target.files[0]?.name,
                                  original_name: evt.target.files[0]?.name,
                                  type: "image",
                                  url: "",
                                })
                              }
                            />
                            <div>
                              Drag a file or{" "}
                              <span className="uk-link">browse</span> to upload
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {!!(attachImage?.name || attachImage?.original_name) && (
                      <MasterConfirm onDelete={deleteImage} />
                    )}
                  </div>
                  <progress
                    id="js-progressbar"
                    className="uk-progress"
                    value="0"
                    max="100"
                    hidden
                  ></progress>
                </div>
                <FormRow>
                  <FormInputWrapper label="Video" size="two">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.video,
                        })}
                        type="text"
                        {...register("video")}
                        placeholder="YouTube or Vimeo Embed Link"
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="PDF Label" size="one">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.label,
                        })}
                        type="text"
                        {...register("attachment")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="PDF">
                    <div className="uk-form-custom">
                      {!!(attachPDF.name || attachPDF.original_name) ? (
                        <>
                          <input
                            key="pdf_key"
                            type="file"
                            {...register("attachments")}
                            onChange={(evt) =>
                              setAttachPDF({
                                name: evt.target.files[0]?.name,
                                original_name: evt.target.files[0]?.name,
                                type: "file",
                                url: "",
                              })
                            }
                          />
                          <span className="grey-text">
                            {attachPDF.original_name || attachPDF.original_name}
                          </span>
                        </>
                      ) : (
                        <>
                          <input
                            key="pdf_key"
                            type="file"
                            {...register("attachments")}
                            onChange={(evt) =>
                              setAttachPDF({
                                name: evt.target.files[0]?.name,
                                original_name: evt.target.files[0]?.name,
                                type: "file",
                                url: "",
                              })
                            }
                          />
                          <div className="uk-placeholder hms-file-uploader uk-text-center">
                            <Image
                              src="/images/upload-pdf.svg"
                              className="hms-navbar"
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {!!attachPDF?.original_name && (
                      <MasterConfirm onDelete={deletePDF} />
                    )}
                  </FormInputWrapper>
                </FormRow>

                <input
                  className={classnames("uk-input", {
                    error: errors["welcome_guide_question_id"],
                  })}
                  type="hidden"
                  {...register("welcome_guide_question_id")}
                />
                <input
                  className={classnames("uk-input", {
                    error: errors["parent_id"],
                  })}
                  type="hidden"
                  {...register("parent_id")}
                />
                <input
                  className={classnames("uk-input", {
                    error: errors["rental_id"],
                  })}
                  type="hidden"
                  {...register("rental_id")}
                />

                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyGuideEdit;
