import React, { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CopyToClipboard from "react-copy-to-clipboard";
import classnames from "classnames";
import { AppContexts } from "../../providers";

const schema = yup.object().shape({
  phone: yup.string().required(),
});

const Share = () => {
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { rental, setRental } = useContext(AppContexts.RentalsContext);
  const { shareGuide } = useContext(AppContexts.UtilContext);

  const offcanvasRef = useRef(null);

  const handleBeforeHide = (e) => {
    if (e.target === offcanvasRef.current) {
      reset();
      setRental({});
    }
  };

  useEffect(() => {
    const offcanvasElement = UIkit.offcanvas(offcanvasRef.current);
    const offcanvasDOMElement = offcanvasElement.$el;
    offcanvasDOMElement.addEventListener("beforehide", handleBeforeHide);
    return () => {
      offcanvasDOMElement.removeEventListener("beforehide", handleBeforeHide);
    };
  }, []);

  const handleShare = async (data) => {
    let result = await shareGuide({
      guide_type: "rental",
      guide_id: rental.rental_id,
      phone: data.phone,
    });
    if (!!result) {
      UIkit.modal("#share-modal").hide();
      UIkit.notification("Home Guide Sent", "success");
    } else {
      UIkit.notification("Failed Sending Area Guide", "error");
    }
  };

  const handleError = (err) => {
    console.log("error:", err);
  };

  return (
    <div
      id="share-modal"
      className="uk-modal-full hms-modals uk-modal"
      uk-offcanvas="overlay: true; flip: true;"
      ref={offcanvasRef}
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Share Home Guide</h2>
          </div>
          <div className="uk-modal-body">
            <div className="uk-grid-small uk-margin-top">
              <div className="uk-width-1-1">
                <div className="uk-width-3-4 uk-display-inline-block">
                  <label className="uk-form-label">Home Guide URL</label>
                  <div className="uk-form-controls">
                    <input
                      className="uk-input"
                      type="text"
                      value={`https://guide.ruebarue.com/rental/${rental.rental_id}`}
                    />
                  </div>
                </div>
              </div>
              <div className="uk-modal-footer">
                <CopyToClipboard
                  text={`https://guide.ruebarue.com/rental/${rental.rental_id}`}
                >
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="button"
                  >
                    Copy
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <form
              className="uk-grid-small"
              onSubmit={handleSubmit(handleShare, handleError)}
            >
              <div className="uk-width-1-1">
                <div className="uk-width-3-4 uk-display-inline-block">
                  <label className="uk-form-label">Text Home Guide</label>
                  <div className="uk-form-controls">
                    <input
                      className={classnames("uk-input", {
                        error: errors["phone"],
                      })}
                      placeholder="Mobile Number"
                      {...register("phone")}
                    />
                  </div>
                </div>
              </div>
              <div className="uk-modal-footer">
                <button className="uk-button hms-btn hms-red-btn" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
