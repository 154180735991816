// Import package components
import { useMemo, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "uikit-react";
import { AppContexts } from "../../../providers";

const ReportsNavbar = () => {
  const {
    startDate,
    endDate,
    active,
    setActive,
    getOverview,
    getHistory,
    getGuestbook,
    getRental,
    getGuide,
    getAutomatedSms,
    getAutomatedEmail,
    getMacros,
    getFiveStar,
    getExtendStay,
    getGuestChecks,
    getWebChat,
  } = useContext(AppContexts.ReportsContext);

  const history = useHistory();
  const pathArray = useMemo(() => history.location.pathname.split("/"), [
    history.location.pathname,
  ]);
  const path = pathArray[pathArray.length - 1].replace("#", "");

  const reportMap = {
    overview: getOverview,
    "message-log": getHistory,
    "guestbook-views": getGuestbook,
    "home-guide-views": getRental,
    "area-guide-views": getGuide,
    "automated-sms-sent": getAutomatedSms,
    "automated-email-sent": getAutomatedEmail,
    macros: getMacros,
    "fivestar-rating": getFiveStar,
    "extend-stay": getExtendStay,
    "check-in-out": getGuestChecks,
    webchat: getWebChat,
  };

  const reportTabs = [
    { path: "overview", label: "Overview" },
    { path: "message-log", label: "Text Message Logs" },
    { path: "guestbook-views", label: "Guestbook Views" },
    { path: "home-guide-views", label: "Home Guide Views" },
    { path: "area-guide-views", label: "Area Guide Views" },
    { path: "automated-sms-sent", label: "Automated Text Sent" },
    { path: "automated-email-sent", label: "Automated Emails Sent" },
    { path: "macros", label: "Property Macros" },
    { path: "fivestar-rating", label: "Five Star Ratings" },
    { path: "extend-stay", label: "Extend Stays Text Sent" },
    { path: "check-in-out", label: "Guest Check-In/Check-Out" },
    { path: "webchat", label: "Webchat Activities" },
  ];

  useEffect(() => {
    if (path === "reports") history.push("/reports/overview");
    setActive(path);
    const fetchData = reportMap[path];
    if (fetchData) {
      fetchData(startDate, endDate);
    }
  }, [path]);

  return (
    <div className="uk-width-auto">
      <ul
        className="uk-tab-left hms-report-option-tabs hms-left-tabs"
        uk-tab="connect: #reports-tab-left; animation: uk-animation-fade"
      >
        {reportTabs.map(({ path, label }) => (
          <li
            key={path}
            className={active === path ? "uk-active" : ""}
            onClick={() => history.push(`/reports/${path}`)}
          >
            <Link href="#">{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportsNavbar;
