// Import package components
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Image, Link, Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import moment from "moment";
import debounce from "lodash.debounce";

// Import common components
import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import AddTextLine from "../../common/AddTextLine";

// Import work-order components
import WorkOrderAdd from "../work_order_add";
import WorkOrderDelete from "../work_order_delete";
import classnames from "classnames";

import { AppContexts } from "../../../providers";
import Loading from "../../common/Loading";
import TableLoading from "../../common/Loading/table_loading";
import Tags from "../../common/Tags";
import Properties from "../../common/Properties";

const WorkOrderList = () => {
  const params = useParams();

  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalContext = useContext(AppContexts.RentalsContext);
  const workOrderContext = useContext(AppContexts.WorkOrderContext);

  const { user } = useContext(AppContexts.AuthContext);

  const { register, getValues, setValue, watch } = useForm();

  const { workOrder, workOrders, total, loading } = workOrderContext;
  const { rentals } = rentalContext;

  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;
  const [sorter, setSorter] = useState("updated_at");
  const [criteria, setCriteria] = useState({
    search_term: "",
    filters: "new",
    page: 0,
    sort: "updated_at",
    category: "all",
    priority: "all",
    assignee: "all",
    tag: "all",
  });

  const tagValues = useMemo(
    () =>
      settingsContext.settings.tags?.sort((a, b) =>
        a.name.localeCompare(b.name)
      ) || [],
    [settingsContext.settings]
  );

  const assignees = useMemo(() => {
    let { recipients, contacts, assignees } = workOrderContext.assigners;

    return [
      ...workOrderContext.assigners.recipients
        .filter((recipient) => {
          return !!recipient.user_id && !recipient.deleted;
        })
        .map((recipient) => {
          return {
            label: `${recipient.first_name} ${recipient.last_name}`,
            value: `${recipient.recipient_id}`,
          };
        }),
      ...workOrderContext.assigners.contacts
        .filter((contact) => {
          return !!assignees.find(
            (a) =>
              a.assignee_type === "contact" && +a.assignee_id === +contact.id
          );
        })
        .map((contact) => {
          return {
            label: `${contact.first_name} ${contact.last_name}`,
            value: `${contact.id}`,
          };
        }),
    ];
  }, [workOrderContext.assigners]);

  useEffect(() => {
    document.title = "Work Orders - RueBaRue";
    if (user) {
      rentalContext.searchRentals({ search_term: "" });
      settingsContext.get(["tags"]);
    }

    let slug = params.slug || "";
    workOrderContext.getAssigners(slug);
    workOrderContext.getTags(slug);

    Object.keys(criteria).forEach((key) => {
      setValue(key, criteria[key]);
    });

    return () => {
      debounceSearch.cancel();
    };
  }, []);

  useEffect(() => {
    let newCriteria = {
      ...criteria,
      search_term: getValues("search_term"),
      page: getValues("page"),
      filters: getValues("filters"),
      priority: getValues("priority"),
      category: getValues("category"),
      assignee: getValues("assignee"),
      tag: getValues("tag"),
    };
    setCriteria(newCriteria);
    workOrderContext.getWorkOrders(params.slug, newCriteria, recordsPerPage);
  }, [
    watch("search_term"),
    watch("page"),
    watch("filters"),
    watch("priority"),
    watch("category"),
    watch("assignee"),
    watch("tag"),
    recordsPerPage,
  ]);

  const property = (id) => rentals.find((rental) => rental.rental_id === id);
  const handlePageChange = useCallback((page) => setValue("page", page), []);
  const searchTerm = useMemo(
    () => getValues("search_term"),
    [watch("search_term")]
  );
  const handleSearchSummary = (evt) => {
    setValue("search_term", evt.target.value);
  };
  const debounceSearch = useMemo(() => debounce(handleSearchSummary, 500), []);

  const handleAddWorkOrder = useCallback(() => {
    workOrderContext.setIsNew(true);
    workOrderContext.initWorkOrder();
  }, []);

  const handleEditWorkOrder = useCallback((id) => {
    workOrderContext.setIsNew(false);
    workOrderContext.getWorkOrder(id);
  }, []);

  const handleClickDelete = (workOrder) =>
    workOrderContext.setWorkOrder(workOrder);

  const handleChangeTags = (tag, selected, destOrder) => {
    let updatedOrder = {};
    if (selected) {
      updatedOrder = {
        ...destOrder,
        tags: [...(destOrder?.tags || []), tag],
      };
    } else {
      updatedOrder = {
        ...destOrder,
        tags: (destOrder.tags || []).filter(
          (existing) => existing.id !== tag.id
        ),
      };
    }

    workOrderContext.updateWorkOrder(updatedOrder.id, updatedOrder);
  };

  const getAssignees = (workOrder) =>
    workOrder.work_order_assignees
      .map((assignee) => {
        let idx;
        switch (assignee.assignee_type) {
          case "recipient":
            idx = workOrderContext.assigners.recipients.findIndex(
              (recipient) =>
                recipient.recipient_id.toString() ===
                assignee.assignee_id.toString()
            );
            if (idx > -1) return workOrderContext.assigners.recipients[idx];
            break;
          case "contact":
            idx = workOrderContext.assigners.contacts.findIndex(
              (contact) =>
                contact.id.toString() === assignee.assignee_id.toString()
            );
            if (idx > -1) return workOrderContext.assigners.contacts[idx];
            break;
          default:
            break;
        }
      })
      .filter((a) => a)
      .map((d) => `${d.first_name} ${d.last_name}`)
      .join(", ");

  const tagged_workorders = useMemo(
    () =>
      workOrders.map((item) => {
        item.tags?.sort((prev, next) => prev.name.localeCompare(next.name));
        return { ...item, tags: item.tags };
      }),
    [workOrders]
  );

  const sorted_workorders = useMemo(() => {
    return tagged_workorders.sort((prev, next) => {
      if (sorter === "assignee") {
        return getAssignees(prev).localeCompare(getAssignees(next));
      }
      if (sorter === "updated_at" || sorter === "created_at") {
        return next[sorter].localeCompare(prev[sorter]);
      }
      return prev[sorter].localeCompare(next[sorter]);
    });
  }, [tagged_workorders, sorter]);

  const diffDate = (order) => {
    let diff = moment.duration(moment().diff(moment(order.created_at)));

    let year = diff.years();
    let month = diff.months();
    let day = diff.days();
    let hour = diff.hours();

    if (year) {
      return `${year} year(s) ago`;
    } else if (month) {
      return `${month} month(s) ago`;
    } else if (day) {
      return `${day} day(s) ago`;
    } else if (hour) {
      return `${hour} hour(s) ago`;
    }
  };

  const handleSelectedTags = useCallback((tags, order) => {
    let updated = { ...order, tags };
    workOrderContext.updateWorkOrder(updated.id, updated);
  }, []);

  const handleSelectedRentals = useCallback((rentals, order) => {
    let updated = { ...order, rentals };
    workOrderContext.updateWorkOrder(updated.id, updated);
  }, []);

  // return workOrderContext.loading ? <Loading /> : renderWorkOrders();

  return (
    <>
      <section id="work-order-template">
        <AddTextLine feature="Work Orders" />
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Work Orders</h1>
        </div>
        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #work-order-add"
                onClick={handleAddWorkOrder}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Work Order
              </button>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
            <div className="uk-overflow-auto">
              <div className="uk-flex uk-flex-between uk-flex-top workorder-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("priority")}>
                          <option value="all">All Priorities</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("category")}>
                          <option value="all">All Categories</option>
                          <option value="air">Air Conditioner</option>
                          <option value="appliance">Appliance</option>
                          <option value="damage">Damage</option>
                          <option value="electrical">Electrical</option>
                          <option value="hot tub">Hot Tub</option>
                          <option value="housekeeping">Housekeeping</option>
                          <option value="inspection">Inspection</option>
                          <option value="mechanical">Mechanical</option>
                          <option value="plumbing">Plumbing</option>
                          <option value="pool">Pool</option>
                          <option value="preventive">Preventive</option>
                          <option value="project">Project</option>
                          <option value="refrigeration">Refrigeration</option>
                          <option value="safety">Safety</option>
                          <option value="standard operating procedure">
                            Standard Operating Procedure
                          </option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("assignee")}>
                          <option value="all">All Assignees</option>
                          {assignees.map((assignee, idx) => {
                            return (
                              <option
                                key={`assignee_${idx}`}
                                value={assignee.value}
                              >
                                {assignee.label}
                              </option>
                            );
                          })}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("tag")}>
                          <option value="all">All Tags</option>
                          {tagValues.map((tag, idx) => {
                            return (
                              <option key={`tag_select_${idx}`} value={tag.id}>
                                {tag.name}
                              </option>
                            );
                          })}
                        </select>

                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {searchTerm && searchTerm !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() => setValue("search_term", "")}
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Order number or Summary"
                          {...register("search_term")}
                          onChange={debounceSearch}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="uk-flex uk-flex-between">
                <div>
                  <ul data-uk-tab>
                    <li
                      className="uk-active"
                      onClick={() => setValue("filters", "new")}
                    >
                      <Link href="#">NEW</Link>
                    </li>
                    <li onClick={() => setValue("filters", "in-progress")}>
                      <Link href="#">IN-PROGRESS</Link>
                    </li>
                    <li onClick={() => setValue("filters", "resolved")}>
                      <Link href="#">RESOLVED</Link>
                    </li>
                    <li onClick={() => setValue("filters", "closed")}>
                      <Link href="#">CLOSED</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <div>
                    <div className="hms-sortby-btn">
                      <button className="uk-button" type="button">
                        SORT BY<span uk-icon="icon: triangle-down"></span>
                      </button>
                      <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                        <ul className="uk-nav uk-dropdown-nav">
                          <li
                            className={classnames({
                              "uk-active": sorter === "updated_at",
                            })}
                            onClick={() => setSorter("updated_at")}
                          >
                            <Link href="#">Updated At</Link>
                          </li>
                          <li
                            className={classnames({
                              "uk-active": sorter === "created_at",
                            })}
                            onClick={() => setSorter("created_at")}
                          >
                            <Link href="#">Created At</Link>
                          </li>
                          <li
                            className={classnames({
                              "uk-active": sorter === "summary",
                            })}
                            onClick={() => setSorter("summary")}
                          >
                            <Link href="#">Summary</Link>
                          </li>
                          <li
                            className={classnames({
                              "uk-active": sorter === "assignee",
                            })}
                            onClick={() => setSorter("assignee")}
                          >
                            <Link href="#">Assignee</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="uk-table-expand">
                      Summary/Property ID/ADDRESS
                    </th>
                    <th>Priority</th>
                    <th>Category</th>
                    <th>Assigned To</th>
                    <th>Reporter</th>
                    {/* <th>Properties</th> */}
                    <th>Tags</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    TableLoading(10)
                  ) : sorted_workorders.length > 0 ? (
                    sorted_workorders.map((workOrder, idx) => (
                      <tr key={workOrder.id}>
                        <td>
                          <div>{workOrder.id}</div>
                        </td>
                        <td>
                          <div>
                            {workOrder.summary}
                            <div>{workOrder.guest_reservation?.name}</div>
                            <div className="secondary">
                              {property(workOrder.rental_id)?.pms_id}
                            </div>
                            <div className="secondary">
                              {property(workOrder.rental_id)?.address}
                            </div>
                            <div className="secondary">
                              {/* workOrder.guest_reservation
                                  ? `Reported By Guest: ${workOrder.guest_reservation?.first_name} ${workOrder.guest_reservation?.last_name}`
                                  : "" */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="hms-booking-tag">
                            {workOrder.priority}
                          </div>
                        </td>
                        <td>
                          <div>{workOrder.category}</div>
                        </td>
                        <td>
                          <div>{getAssignees(workOrder)}</div>
                        </td>
                        <td>
                          <div>
                            {workOrder.reporter?.first_name +
                              " " +
                              workOrder.reporter?.last_name}
                          </div>
                        </td>

                        {/* <td className="hms-add-tag">
                          <Properties
                            value={workOrder?.rentals || []}
                            onChange={(editedTags) =>
                              handleSelectedRentals(editedTags, workOrder)
                            }
                          />
                        </td> */}
                        <td className="hms-add-tag">
                          <Tags
                            value={workOrder?.tags || []}
                            onChange={(event) =>
                              handleSelectedTags(event, workOrder)
                            }
                          />
                        </td>
                        <td>
                          <div>
                            <div>
                              {moment(workOrder.created_at).format(
                                "MM/DD/YYYY"
                              )}
                            </div>
                            <div className="secondary">
                              {diffDate(workOrder)}
                            </div>
                          </div>
                        </td>
                        <td className="hms-action-btn">
                          <div className="uk-flex uk-flex-top">
                            <div className="uk-inline">
                              <button
                                className="uk-button"
                                uk-toggle="target: #work-order-add"
                                onClick={() =>
                                  handleEditWorkOrder(workOrder.id)
                                }
                                uk-tooltip="Edit"
                              >
                                <Image src="/images/edit.svg" />
                              </button>
                            </div>
                            <div className="uk-inline">
                              {params.slug ? (
                                <a
                                  href={`/work-orders/${params.slug}/${workOrder.id}`}
                                  target="_blank"
                                >
                                  <button
                                    className="uk-button"
                                    uk-tooltip="Comment"
                                  >
                                    <Image src="/images/workorder-comment.svg" />
                                  </button>
                                </a>
                              ) : (
                                <a
                                  href={`/work-orders/${workOrder.id}`}
                                  target="_blank"
                                >
                                  <button
                                    className="uk-button"
                                    uk-tooltip="Comment"
                                  >
                                    <Image src="/images/workorder-comment.svg" />
                                  </button>
                                </a>
                              )}
                            </div>
                            {/* <div className="uk-inline">
                                <button className="uk-button">
                                  <Image src="/images/sms.svg" />
                                </button>
                              </div> */}
                            <div className="uk-inline">
                              <button
                                className="uk-button"
                                uk-toggle="target: #work-order-delete"
                                onClick={() => handleClickDelete(workOrder)}
                                uk-tooltip="Delete"
                              >
                                <Image src="/images/delete.svg" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        style={{ padding: "30px 25px", textTransform: "none" }}
                      >
                        No work orders matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={total}
              index={0}
              onIndexChanged={handlePageChange}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <WorkOrderAdd />
      <WorkOrderDelete />
    </>
  );
};

export default WorkOrderList;
